
import { Component, Prop, Vue } from "vue-property-decorator";
import Page from "../components/layout/Page.vue";

@Component({
  components: {
    Page,
  },
})
export default class Imprint extends Vue {}
